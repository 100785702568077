import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/Header';
import Footer from './components/Footer';
import Modal from './components/Modal';
import Testimonials from './components/Testimonials';
import './App.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="App">
      <Header />
      <main>
        <section className="hero">
          <h1>Understand your bank statement the right way with AI</h1>
          <div className="star-rating">★★★★★</div>
          <p>Upload your bank statement and let our AI uncover hidden gems from ways to save more, spotting unusual charges to optimizing your spending and more</p>
          <button onClick={() => setIsModalOpen(true)}>Get Started</button>
        </section>
        <Elements stripe={stripePromise}>
          <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </Elements>
        <Testimonials />
      </main>
      <Footer />
    </div>
  );
}

export default App;