import React, { useState, useEffect, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { extractText } from '../utils/pdfUtils';

function Modal({ isOpen, onClose }) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const modalRef = useRef(null); // Create a ref for the modal

  const handleFileUpload = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    setIsLoading(true); // Show loading spinner
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const extractedText = await extractText(new Uint8Array(e.target.result));
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/analyze-statement`, { text: extractedText });
        localStorage.setItem('analysisResults', JSON.stringify(response.data));
        setIsLoading(false);
        setShowPayment(true);
      } catch (error) {
        console.error('Error in analysis:', error);
        alert(`Error: ${error.message}\nPlease check the console for more details.`);
        setIsLoading(false);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handlePayment = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('Error:', error);
        alert('Payment failed: ' + error.message);
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/process-payment`, { paymentMethodId: paymentMethod.id });

      if (response.data.clientSecret) {
        const result = await stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: paymentMethod.id,
        });

        if (result.error) {
          // Show error to customer
          console.error('Stripe confirmation error:', result.error);
          alert(`Payment failed: ${result.error.message}`);
        } else if (result.paymentIntent.status === 'succeeded') {
          // Payment succeeded
          window.location.href = '/results';
        } else {
          // Handle other statuses if necessary
          alert('Payment processing. Please wait...');
        }
      } else {
        alert('Unable to process payment. Please try again later.');
      }
    } catch (error) {
      console.error('Error processing payment:', error.response ? error.response.data : error.message);
      alert(`Payment failed: ${error.response ? error.response.data.error : error.message}`);
    }
  };

  const handleClose = () => {
    // Reset state when closing the modal
    setFile(null);
    setIsLoading(false);
    setShowPayment(false);
    onClose(); // Call the onClose prop to close the modal
  };

  // Close modal when clicking outside of it
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the modal
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content" ref={modalRef}>
        <span className="close" onClick={handleClose}>&times;</span>
        {isLoading ? (
          <div className="loading">
            <div className="spinner"></div>
          </div>
        ) : showPayment ? (
          <section className="payment-section" style={{ textAlign: 'center' }}>
            <h2 style={{ color: '#4CAF50' }}>Complete Your Payment</h2>
            <p style={{ fontSize: '1.2em' }}>Your bank statement has been analyzed. Please complete the payment of <strong>$2.99</strong> to view the results.</p>
            <div id="card-element" style={{ margin: '20px 0' }}>
              <CardElement options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#424770',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }} />
            </div>
            <button id="payment-btn" onClick={handlePayment} style={{ backgroundColor: '#4CAF50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              Pay $2.99 and View Results
            </button>
          </section>
        ) : (
          <section className="upload-section">
            <h2>Upload Your Bank Statement</h2>
            <p>Get started by uploading your bank statement in PDF format.</p>
            <input type="file" id="file-upload" accept="application/pdf" onChange={(e) => setFile(e.target.files[0])} />
            <button id="upload-btn" type="button" onClick={handleFileUpload}>Upload PDF</button>
          </section>
        )}
      </div>
    </div>
  );
}

export default Modal;