import React from 'react';

function Testimonials() {
  const testimonials = [
    { text: "BankStatementAI saved me $300 in just the first month! It's worth every penny.", author: "John Felix" },
    { text: "I was surprised by how many unnecessary charges I had. Highly recommended!", author: "Jane Smith" },
    { text: "BankStatementAI should cost twice as much for the value it provides!", author: "Mike Johnson" },
    { text: "Absolutely amazing. Found hidden savings I never knew existed.", author: "Sarah Brown" },
    { text: "The best financial decision I've made this year. It's a game-changer!", author: "Chris Evans" },
    { text: "If you're not using it, you're missing out on huge savings!", author: "Jessica White" },
    { text: "I can't believe how much money I've saved. This is a must-have.", author: "David Wilson" },
    { text: "Worth every penny. My financial habits have improved drastically.", author: "Emily Davis" },
    { text: "Unbelievable insights into my spending habits. Highly recommended!", author: "James Moore" },
    { text: "I found out about subscriptions I had forgotten about. Amazing!", author: "Anna Taylor" },
    { text: "This app should be priced higher for the savings it offers.", author: "Daniel Martinez" },
    { text: "A must-have tool for anyone serious about saving money.", author: "Sophia Anderson" },
    { text: "Incredible app! I can't believe how much I've saved.", author: "Alexander Thomas" },
    { text: "My spending has become so much more efficient.", author: "Olivia Martin" },
    { text: "Should be a standard tool for everyone. It's amazing.", author: "Henry Lewis" },
    { text: "Can't imagine my finances without BankStatementAI anymore.", author: "Isabella Walker" },
  ];

  return (
    <section className="testimonials">
      <h2>What are people saying about BankStatementAI</h2>
      <div className="testimonial-grid">
        {testimonials.map((testimonial, index) => (
          <div className="testimonial" key={index}>
            <p>"{testimonial.text}" - {testimonial.author}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;