import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Results() {
  const [analysis, setAnalysis] = useState(null);

  useEffect(() => {
    const analysisResults = JSON.parse(localStorage.getItem('analysisResults'));
    if (analysisResults) {
      setAnalysis(analysisResults);
      localStorage.removeItem('analysisResults');
    }
  }, []);

  if (!analysis) {
    return (
      <div>
        <Header />
        <main>
          <section id="analysis-result">
            <h1>No analysis results found.</h1>
          </section>
        </main>
        <Footer />
      </div>
    );
  }

  const {
    fun_report,
    detailed_insights,
    hidden_gems,
    personalized_goals,
    investment_opportunities,
  } = analysis;

  return (
    <div>
      <Header />
      <main>
        <section id="analysis-result">
          <h1>🎉 Your Financial Insights Await! 🏦</h1>
          <div id="analysis-message">
            {fun_report && (
              <div className="fun-report">
                <h2>Fun Report</h2>
                <p><strong>Summary:</strong> {fun_report.summary}</p>
                <p><strong>Funny Observation:</strong> {fun_report.funny_observation}</p>
                <p><strong>Savings Tip:</strong> {fun_report.savings_tip}</p>
              </div>
            )}

            <div className="detailed-insights">
              <h2>Detailed Insights</h2>
              <ul>
                <li><strong>Total Income:</strong> ${detailed_insights.total_income.toFixed(2)}</li>
                <li><strong>Total Expenses:</strong> ${detailed_insights.total_expenses.toFixed(2)}</li>
                <li><strong>Net Income:</strong> ${detailed_insights.net_income.toFixed(2)}</li>
                <li>
                  <strong>Largest Expense:</strong> {detailed_insights.largest_expense.description} (${detailed_insights.largest_expense.amount.toFixed(2)}) on {new Date(detailed_insights.largest_expense.date).toLocaleDateString()}
                </li>
              </ul>

              <div className="income-sources">
                <h3>Income Sources</h3>
                <ul>
                  {detailed_insights.income_sources.map((source, index) => (
                    <li key={index}>
                      {source.source}: ${source.amount.toFixed(2)} ({source.frequency})
                    </li>
                  ))}
                </ul>
              </div>

              <div className="spending-categories">
                <h3>Spending Categories</h3>
                <ul>
                  {detailed_insights.spending_categories.map((category, index) => (
                    <li key={index}>
                      {category.category}: ${category.amount.toFixed(2)} ({category.percentage_of_total.toFixed(2)}%)
                    </li>
                  ))}
                </ul>
              </div>

              <div className="cash-flow-analysis">
                <h3>Cash Flow Analysis</h3>
                <h4>Monthly Trends</h4>
                <ul>
                  {detailed_insights.cash_flow_analysis.monthly_trends.map((trend, index) => (
                    <li key={index}>
                      {trend.month}: Income - ${trend.income.toFixed(2)}, Expenses - ${trend.expenses.toFixed(2)}, Net Flow - ${trend.net_flow.toFixed(2)}
                    </li>
                  ))}
                </ul>
                <h4>Forecast</h4>
                <ul>
                  <li><strong>Projected Income:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_income.toFixed(2)}</li>
                  <li><strong>Projected Expenses:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_expenses.toFixed(2)}</li>
                  <li><strong>Projected Savings:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_savings.toFixed(2)}</li>
                </ul>
              </div>
            </div>

            {personalized_goals && (
              <div className="personalized-goals">
                <h2>Personalized Goals</h2>
                <div className="short-term-goals">
                  <h3>Short-Term Goals</h3>
                  <ul>
                    {personalized_goals.short_term.map((goal, index) => (
                      <li key={index}>
                        <strong>Goal:</strong> {goal.goal} - <strong>Target Amount:</strong> ${goal.target_amount.toFixed(2)} - <strong>Deadline:</strong> {new Date(goal.deadline).toLocaleDateString()}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="long-term-goals">
                  <h3>Long-Term Goals</h3>
                  <ul>
                    {personalized_goals.long_term.map((goal, index) => (
                      <li key={index}>
                        <strong>Goal:</strong> {goal.goal} - <strong>Target Amount:</strong> ${goal.target_amount.toFixed(2)} - <strong>Deadline:</strong> {new Date(goal.deadline).toLocaleDateString()}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {investment_opportunities && investment_opportunities.length > 0 && (
              <div className="investment-opportunities">
                <h2>Investment Opportunities</h2>
                <ul>
                  {investment_opportunities.map((opportunity, index) => (
                    <li key={index}>
                      <strong>Asset:</strong> {opportunity.asset} - <strong>Suggested Amount:</strong> ${opportunity.suggested_amount.toFixed(2)} - <strong>Potential Return:</strong> {opportunity.potential_return}% - <strong>Risk Level:</strong> {opportunity.risk_level}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {hidden_gems && hidden_gems.length > 0 && (
              <div className="hidden-gems">
                <h2>Hidden Gems</h2>
                <ul>
                  {hidden_gems.map((gem, index) => (
                    <li key={index}>
                      <strong>{gem.description}</strong>: {gem.insight} - <em>{gem.actionable_tip}</em>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Results;