import React from 'react';

function Header() {
  return (
    <header>
      <a href="/" className="logo" style={{ textDecoration: 'none', paddingLeft: '15px' }}>
        🏦 BankStatementAI
      </a>
    </header>
  );
}

export default Header;